import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import dayjs, { Dayjs } from 'dayjs';
import { MultipleSelectItem } from 'components/Input/Select/MultipleSelect';
import { AnalyticsFilter } from 'views/AlarmsView/filters/AnalyticsFilter';
import { CameraZoneFilter } from 'views/AlarmsView/filters/CameraZoneFilter';
import { TimeFilters } from 'views/AlarmsView/filters/TimeFilters';
import { StreamsFilter } from 'views/AlarmsView/filters/StreamsFilter';
import { IgnoredStatus, ResolvedStatus, TimeUnit } from 'types/alarmTypes';
import { IgnoredFilter } from 'views/AlarmsView/filters/IgnoredFilter';
import { ResolvedFilter } from 'views/AlarmsView/filters/ResolvedFilter';
import { FavouriteOnlyFilter } from './FavouriteOnlyFilter';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import SearchIcon from '@material-ui/icons/Search';
import { useAlarmFilters } from 'context/providers/AlarmFiltersProvider';
import clsx from 'clsx';

type AlarmFiltersProps = {
  disabled: boolean;
};

export const AlarmFilters = ({ disabled }: AlarmFiltersProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setAlarmFilters } = useAlarmFilters();
  const [timeframe, setTimeframe] = useState<{
    from: Dayjs | null;
    to: Dayjs | null;
  }>({ from: dayjs().set('hour', 0).set('minute', 0), to: null });
  const [fromLast, setFromLast] = useState<{
    number: string | number;
    unit: string;
  }>({ number: 1, unit: TimeUnit.MINUTES });
  const [analytics, setAnalytics] = useState<MultipleSelectItem[]>([]);
  const [zones, setZones] = useState<MultipleSelectItem[]>([]);
  const [streams, setStreams] = useState<MultipleSelectItem[]>([]);
  const [ignoredStatus, setIgnoredStatus] = useState(IgnoredStatus.UNIGNORED);
  const [resolvedStatus, setResolvedStatus] = useState(
    ResolvedStatus.UNRESOLVED
  );
  const [favouriteOnly, setFavouriteOnly] = useState(false);
  const [mode, setMode] = useState({
    liveMode: true,
    lastAlarms: false,
    timeframe: false,
  });

  const handleFilterClick = () => {
    setAlarmFilters({
      timeframe,
      fromLast,
      analytics,
      zones,
      streams,
      ignoredStatus,
      resolvedStatus,
      favouriteOnly,
      mode,
    });
  };

  return (
    <div className={classes.filtersWrapper}>
      <div className={classes.upperRow}>
        <TimeFilters
          mode={mode}
          setMode={setMode}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          fromLast={fromLast}
          setFromLast={setFromLast}
        />
        <FavouriteOnlyFilter
          isChecked={favouriteOnly}
          onChange={setFavouriteOnly}
        />
      </div>
      <div className={classes.multiFilters}>
        <AnalyticsFilter
          selectedAnalytics={analytics}
          setSelectedAnalytics={setAnalytics}
        />
        <CameraZoneFilter selectedZones={zones} setSelectedZones={setZones} />
        <StreamsFilter
          selectedStreams={streams}
          setSelectedStreams={setStreams}
        />
        <ResolvedFilter value={resolvedStatus} setValue={setResolvedStatus} />
        <IgnoredFilter value={ignoredStatus} setValue={setIgnoredStatus} />
        <ButtonPrimary
          startIcon={
            <SearchIcon
              className={clsx({ [classes.disabledIcon]: disabled })}
            />
          }
          className={classes.submit}
          onClick={handleFilterClick}
          disabled={disabled}
        >
          {t('filter')}
        </ButtonPrimary>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  multiFilters: {
    display: 'flex',
    justifyItems: 'start',
    alignItems: 'center',
  },
  filtersWrapper: {
    marginBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
  upperRow: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(3, 0, 2, 0),
  },
  submit: {
    marginLeft: theme.spacing(6),
    fontSize: '14px',
    minWidth: '100px',
  },
  disabledIcon: {
    color: theme.palette.text.disabled,
  },
}));
