import {
  Card,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AlarmCardActions } from 'views/AlarmsView/AlarmCard/AlarmCardActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import { clsx } from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { AlarmCardMedia } from 'views/AlarmsView/AlarmCard/AlarmCardMedia';
import { AlarmCardDetails } from 'views/AlarmsView/AlarmCard/AlarmCardDetails';
import { Alarm, AlarmMediaStatus, UserUpdateAlarmDTO } from 'types/alarmTypes';
import { apiAddress } from 'api/config/interceptors';
import { useTranslation } from 'react-i18next';
import { AlertSeverity } from 'components/Alert/Toast';

type AlarmCardProps = {
  alarm: Alarm;
  updateAlarmCard: (updatedId: string, payload: UserUpdateAlarmDTO) => void;
  setFeedback: Dispatch<
    SetStateAction<{
      open: boolean;
      status: AlertSeverity;
      msg: string;
    }>
  >;
};

export const AlarmCard = ({
  alarm,
  updateAlarmCard,
  setFeedback,
  ...rest
}: AlarmCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const determineThumbnailContent = () => {
    const status = alarm.thumbnail.status;
    if (status === AlarmMediaStatus.AVAILABLE) {
      return (
        <img
          className={classes.thumbnail}
          src={`${apiAddress}/api/${alarm.alarmId}/thumbnail`}
          alt="alarm-snapshot"
          draggable="false"
        />
      );
    } else if (status === AlarmMediaStatus.NOT_YET_AVAILABLE) {
      return <Skeleton variant="rect" className={classes.skeleton} />;
    } else {
      return (
        <div className={classes.thumbnailPlaceholder}>
          <Typography variant="subtitle1" className={classes.placeholderText}>
            {t('alarms.not-available')}
          </Typography>
        </div>
      );
    }
  };

  return (
    <Card
      className={clsx(classes.card, {
        [classes.activeAlarm]: !alarm.endTimestamp,
      })}
      {...rest}
    >
      <div>
        <CardContent className={classes.content}>
          <div className={classes.leftSide}>
            <div className={classes.thumbnailContainer}>
              {determineThumbnailContent()}
            </div>
            <AlarmCardDetails alarm={alarm} />
          </div>
          <div className={classes.rightSide}>
            <AlarmCardActions
              alarm={alarm}
              updateAlarmCard={updateAlarmCard}
              setFeedback={setFeedback}
            />
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </CardContent>
      </div>
      <AlarmCardMedia expanded={expanded} alarm={alarm} />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  activeAlarm: {
    boxShadow: theme.shadows[1],
    border: 'none',
  },
  header: {
    padding: theme.spacing(2, 2, 0, 3),
  },
  title: {
    fontSize: '18px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 3, 3),
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    verticalAlign: 'bottom',
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[600],
  },
  expand: {
    marginTop: 'auto',
    marginLeft: 'auto',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  leftSide: {
    display: 'flex',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeleton: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },
  thumbnailPlaceholder: {
    backgroundColor: theme.palette.grey[600],
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },
  thumbnailContainer: {
    width: '300px',
    aspectRatio: '16/9',
    borderRadius: '4px',
    marginRight: theme.spacing(4),
  },
  placeholderText: {
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '169px',
    fontWeight: 400,
  },
}));
