import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, List, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'components/Navbar/MenuItem';
import GroupIcon from '@material-ui/icons/Group';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { LicenseExpInfo } from 'views/StreamsView/LicenseExpInfo';
import LangSelect from 'components/Input/Select/LangSelect';
import { LogoutButton } from 'components/Navbar/LogoutButton';
import { useAuth } from 'context/providers/AuthProvider';

const guestMenu = [
  {
    name: 'streams',
    paths: ['/streams'],
    icon: <OndemandVideoIcon />,
  },
  {
    name: 'alarms',
    paths: ['/alarms'],
    icon: <NotificationsActiveIcon />,
  },
];

const adminMenu = [
  {
    name: 'users',
    paths: ['/users'],
    icon: <GroupIcon />,
  },
  {
    name: 'settings',
    paths: ['/settings'],
    icon: <SettingsIcon />,
  },
];

export type Location = {
  pathname: string;
};

export const MenuContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { isAdmin } = useAuth();

  const chooseMenuItems = () => {
    if (isAdmin) {
      return guestMenu.concat(adminMenu);
    } else {
      return guestMenu;
    }
  };

  const menuItems = chooseMenuItems();

  const calcSelectedItem = () => {
    let selectedItem = 0;
    menuItems.forEach((item, index) => {
      item.paths.forEach((path) => {
        if (location.pathname.includes(path)) {
          selectedItem = index;
        }
      });
    });
    return selectedItem;
  };

  const selectedItem = calcSelectedItem();

  return (
    <div className={classes.drawerContainer}>
      <Box>
        <div className={classes.logoPlaceholder}>
          <RouterLink to="/streams">
            <img
              src="signet-400px.png"
              alt="noctuai logo"
              className={classes.logo}
            />
          </RouterLink>
        </div>
        <List className={classes.menuList}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={item.name}
              name={item.name}
              path={item.paths[0]}
              icon={item.icon}
              selected={selectedItem === index}
            />
          ))}
        </List>
      </Box>
      <Box className={classes.bottomItems}>
        <LicenseExpInfo />
        <LangSelect />
        <LogoutButton />
        <Typography variant="caption" className={classes.appVersion}>
          {t('version')} {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </div>
  );
};

MenuContent.defaultProps = {
  expanded: true,
};

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'block',
    margin: theme.spacing(2, 'auto', 6, 'auto'),
    width: '120px',
  },
  menuList: {
    padding: theme.spacing(0, 4, 0, 4),
  },
  bottomItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 2, 4),
  },
  logoPlaceholder: {
    height: '120px',
  },
  appVersion: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[400],
  },
}));
