import { Container, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';

export const NoAlarmsScreen = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.noResultsContainer}>
      <div className={classes.noResultsContent}>
        <NotificationsOffOutlinedIcon className={classes.icon} />
        <Typography variant="h2" className={classes.text}>
          {t('alarms.no-results')}
        </Typography>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  noResultsContainer: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultsContent: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '50px',
    fontWeight: 400,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.text.disabled,
    fontWeight: 400,
  },
}));
