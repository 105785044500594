import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Alarm, UserUpdateAlarmDTO } from 'types/alarmTypes';
import { AlarmCard } from 'views/AlarmsView/AlarmCard/AlarmCard';
import { makeStyles } from '@material-ui/core';
import { AlarmFilters } from 'views/AlarmsView/filters/AlarmFilters';
import { useAlarmFilters } from 'context/providers/AlarmFiltersProvider';
import {
  apiUpdateAlarm,
  constructParams,
  convertToApiValues,
  updateAlarmActions,
} from 'views/AlarmsView/helpers';
import { apiAddress } from 'api/config/interceptors';
import { NoAlarmsScreen } from 'views/AlarmsView/NoAlarmsScreen';
import Toast, { AlertSeverity } from 'components/Alert/Toast';
import InfiniteScroll from 'react-infinite-scroller';
import CircularLoader from 'components/Progress/CircularLoader';
import { ScrollToTop } from 'components/Button/ScrollToTop';
import { LiveAlarmsList } from './LiveAlarmsList';

const MAX_ALARMS_LIVE = 30;
const ALARMS_INCREMENT = 30;

const AlarmsView = () => {
  const classes = useStyles();
  const [liveAlarms, setLiveAlarms] = useState<Alarm[]>([]);
  const [nonLiveAlarms, setNonLiveAlarms] = useState<Alarm[]>([]);
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(ALARMS_INCREMENT);
  const { alarmFilters } = useAlarmFilters();
  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
  const [loading, setLoading] = useState(false);

  const liveAlarmsRef = useRef(liveAlarms);
  const setLiveAlarmsRef = (data: Alarm[]) => {
    liveAlarmsRef.current = data;
    setLiveAlarms(data);
  };
  const nonLiveAlarmsRef = useRef(nonLiveAlarms);
  const setNonLiveAlarmsRef = (data: Alarm[]) => {
    nonLiveAlarmsRef.current = data;
    setNonLiveAlarms(data);
  };
  const evtSourceRef = useRef<EventSource | null>(null);

  useEffect(() => {
    if (!alarmFilters.mode.liveMode) {
      setLoading(true);
    }
    evtSourceRef.current?.close();
    setLiveAlarmsRef([]);
    setNonLiveAlarmsRef([]);
    setNumItemsToDisplay(ALARMS_INCREMENT);
    const apiFilters = convertToApiValues(alarmFilters);
    let newAlarmsTimeframe: Alarm[] = [];

    evtSourceRef.current = new EventSource(
      `${apiAddress}/alarming-reporting-api/alarms/stream${constructParams(
        apiFilters
      )}`
    );

    evtSourceRef.current.addEventListener('NEW', (event: MessageEvent) => {
      if (alarmFilters.mode.liveMode) {
        const alarmsToUpdate = cloneDeep(liveAlarmsRef.current);
        if (alarmsToUpdate.length >= MAX_ALARMS_LIVE) {
          alarmsToUpdate.pop();
        }
        setLiveAlarmsRef([JSON.parse(event.data), ...alarmsToUpdate]);
      } else {
        newAlarmsTimeframe.push(JSON.parse(event.data));
      }
    });

    evtSourceRef.current.addEventListener('UPDATE', (event: MessageEvent) => {
      const alarmsToUpdate = cloneDeep(liveAlarmsRef.current);
      const updatedAlarms = apiUpdateAlarm(
        JSON.parse(event.data),
        alarmsToUpdate
      );
      setLiveAlarmsRef(updatedAlarms);
    });

    evtSourceRef.current.addEventListener(
      'SSE_STREAM_STATE',
      (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.status === 'COMPLETED') {
          setNonLiveAlarmsRef(newAlarmsTimeframe);
          newAlarmsTimeframe = [];
          evtSourceRef.current?.close();
          setLoading(false);
        }
      }
    );

    evtSourceRef.current.addEventListener('error', () => {
      setLoading(false);
    });

    return () => {
      evtSourceRef.current?.close();
    };
  }, [alarmFilters]);

  const userUpdateAlarm = (updatedId: string, payload: UserUpdateAlarmDTO) => {
    const currentAlarms = alarmFilters.mode.liveMode
      ? liveAlarmsRef.current
      : nonLiveAlarmsRef.current;
    const setCurrentAlarms = alarmFilters.mode.liveMode
      ? setLiveAlarmsRef
      : setNonLiveAlarmsRef;
    const alarmsToUpdate = cloneDeep(currentAlarms);
    const updatedAlarms = updateAlarmActions(
      updatedId,
      payload,
      alarmsToUpdate
    );
    setCurrentAlarms(updatedAlarms);
  };

  const handleLoadMore = useCallback(() => {
    setNumItemsToDisplay((prevValue) => prevValue + 30);
  }, []);

  const alarmsToShow =
    (alarmFilters.mode.liveMode && !isEmpty(liveAlarmsRef.current)) ||
    (!alarmFilters.mode.liveMode && !isEmpty(nonLiveAlarmsRef.current));

  return (
    <>
      <AlarmFilters disabled={loading} />
      {alarmsToShow ? (
        <>
          {alarmFilters.mode.liveMode ? (
            <LiveAlarmsList
              alarms={liveAlarmsRef.current}
              userUpdateAlarm={userUpdateAlarm}
              setFeedback={setFeedback}
            />
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={nonLiveAlarmsRef.current.length > numItemsToDisplay}
              loader={<CircularLoader key={0} />}
            >
              <>
                {nonLiveAlarmsRef.current
                  .slice(0, numItemsToDisplay)
                  .map((alarm) => {
                    return (
                      <AlarmCard
                        key={alarm.alarmId}
                        alarm={alarm}
                        updateAlarmCard={userUpdateAlarm}
                        setFeedback={setFeedback}
                      />
                    );
                  })}
              </>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <CircularLoader className={classes.loader} />
          ) : (
            <NoAlarmsScreen />
          )}
        </>
      )}
      <ScrollToTop />
      {feedback.open && (
        <Toast
          setState={setFeedback}
          severity={feedback.status}
          msg={feedback.msg}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: theme.spacing(20),
  },
}));

export default AlarmsView;
