import { makeStyles } from '@material-ui/core';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type FavouriteOnlyFilterProps = {
  isChecked: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
};

export const FavouriteOnlyFilter = ({
  isChecked,
  onChange,
}: FavouriteOnlyFilterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange(checked);
  };

  return (
    <CheckboxWithLabel
      disableRipple
      label={t('alarms.favourite-only')}
      checked={isChecked}
      onChange={handleChange}
      color="secondary"
      labelClassName={classes.label}
      className={classes.favourite}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    whiteSpace: 'nowrap',
  },
  favourite: {
    marginLeft: theme.spacing(2),
  },
}));
