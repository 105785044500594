import { Button, IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import clsx from 'clsx';
import { updateAlarmEvent } from 'api/alarmsApi';
import { Alarm, UserUpdateAlarmDTO } from 'types/alarmTypes';
import { Dispatch, SetStateAction } from 'react';
import { AlertSeverity } from 'components/Alert/Toast';

type AlarmCardActionsProps = {
  alarm: Alarm;
  updateAlarmCard: (updatedId: string, payload: UserUpdateAlarmDTO) => void;
  setFeedback: Dispatch<
    SetStateAction<{
      open: boolean;
      status: AlertSeverity;
      msg: string;
    }>
  >;
};

enum AlarmActions {
  RESOLVE = 'RESOLVE',
  IGNORE = 'IGNORE',
  FAVOURITE = 'FAVOURITE',
}

export const AlarmCardActions = ({
  alarm,
  updateAlarmCard,
  setFeedback,
}: AlarmCardActionsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isFavourite, isIgnored, isResolved } = alarm;

  const toggleAlarmState = (action: AlarmActions) => {
    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
    const payload = {
      isFavourite:
        action === AlarmActions.FAVOURITE ? !isFavourite : isFavourite,
      isIgnored: action === AlarmActions.IGNORE ? !isIgnored : isIgnored,
      isResolved: action === AlarmActions.RESOLVE ? !isResolved : isResolved,
    };
    updateAlarmEvent(alarm.alarmId, payload)
      .then(() => updateAlarmCard(alarm.alarmId, payload))
      .catch(() => {
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: 'general-error',
        });
      });
  };

  return (
    <div className={classes.alarmActions}>
      <IconButton
        className={classes.favourite}
        onClick={() => toggleAlarmState(AlarmActions.FAVOURITE)}
        disableRipple
      >
        {alarm.isFavourite ? (
          <StarIcon className={clsx(classes.starChecked, classes.starIcon)} />
        ) : (
          <StarOutlineIcon className={classes.starIcon} />
        )}
      </IconButton>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          className={classes.resolve}
          onClick={() => toggleAlarmState(AlarmActions.RESOLVE)}
          startIcon={
            alarm.isResolved ? <CheckIcon className={classes.icon} /> : null
          }
        >
          {alarm.isResolved ? (
            <>{t('alarms.resolved')}</>
          ) : (
            <>{t('alarms.resolve')}</>
          )}
        </Button>
        <Button
          variant="outlined"
          className={classes.ignore}
          onClick={() => toggleAlarmState(AlarmActions.IGNORE)}
          startIcon={
            alarm.isIgnored ? <CloseIcon className={classes.icon} /> : null
          }
        >
          {alarm.isIgnored ? (
            <>{t('alarms.ignored')}</>
          ) : (
            <>{t('alarms.ignore')}</>
          )}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  alarmActions: {
    display: 'flex',
    height: 'fit-content',
  },
  resolve: {
    marginRight: theme.spacing(2),
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontSize: '14px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      boxShadow: 'none',
    },
  },
  ignore: {
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  favourite: {
    marginRight: theme.spacing(2),
    alignSelf: 'flex-start',
  },
  starChecked: {
    color: 'rgb(252, 229, 23)',
  },
  starIcon: {
    width: '26px',
    height: '26px',
  },
  buttons: {
    alignSelf: 'center',
  },
}));
