import {
  MultipleSelect,
  MultipleSelectItem,
} from 'components/Input/Select/MultipleSelect';
import { useAnalytics } from 'context/providers/AnalyticsProvider';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type AnalyticsFilterType = {
  selectedAnalytics: MultipleSelectItem[];
  setSelectedAnalytics: Dispatch<SetStateAction<MultipleSelectItem[]>>;
};

export const AnalyticsFilter = ({
  selectedAnalytics,
  setSelectedAnalytics,
}: AnalyticsFilterType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { availableAnalytics } = useAnalytics();
  const [inputValues, setInputValues] = useState<MultipleSelectItem[]>([]);

  useEffect(() => {
    const formattedValues = Object.keys(availableAnalytics).map((analytics) => {
      const id = nanoid();
      return {
        id: id,
        name: analytics,
      };
    });
    setInputValues(formattedValues);
  }, [availableAnalytics]);

  return (
    <MultipleSelect
      label={t('alarms.filter-analytics')}
      items={inputValues}
      value={selectedAnalytics}
      setValue={setSelectedAnalytics}
      translated
      translationKey={'analytics'}
      className={classes.analyticsFilter}
      formControlClass={classes.formControlClass}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  analyticsFilter: {
    marginRight: theme.spacing(4),
  },
  formControlClass: {
    maxWidth: '350px',
  },
}));
