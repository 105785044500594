import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import SelectBase from 'components/Input/Select/SelectBase';
import { IgnoredStatus } from 'types/alarmTypes';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

type IgnoredFilterProps = {
  value: IgnoredStatus;
  setValue: Dispatch<SetStateAction<IgnoredStatus>>;
};

export const IgnoredFilter = ({ value, setValue }: IgnoredFilterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = [
    {
      value: IgnoredStatus.UNIGNORED,
      label: t(`alarms.${IgnoredStatus.UNIGNORED}`),
    },
    {
      value: IgnoredStatus.IGNORED,
      label: t(`alarms.${IgnoredStatus.IGNORED}`),
    },
    { value: IgnoredStatus.ALL, label: t(`alarms.${IgnoredStatus.ALL}`) },
  ];

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setValue(event.target.value as IgnoredStatus);
  };

  return (
    <SelectBase
      items={items}
      value={value}
      onChange={handleChange}
      label={t('alarms.ignore-status')}
      className={classes.ignoredFilter}
    />
  );
};

const useStyles = makeStyles(() => ({
  ignoredFilter: {
    maxWidth: '150px',
  },
}));
