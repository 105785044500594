import { Grow } from '@material-ui/core';
import { AlertSeverity } from 'components/Alert/Toast';
import { Dispatch, SetStateAction } from 'react';
import { Alarm, UserUpdateAlarmDTO } from 'types/alarmTypes';
import { AlarmCard } from 'views/AlarmsView/AlarmCard/AlarmCard';

type LiveAlarmsListProps = {
  alarms: Alarm[];
  userUpdateAlarm: (updatedId: string, payload: UserUpdateAlarmDTO) => void;
  setFeedback: Dispatch<
    SetStateAction<{
      open: boolean;
      status: AlertSeverity;
      msg: string;
    }>
  >;
};

export const LiveAlarmsList = ({
  alarms,
  userUpdateAlarm,
  setFeedback,
}: LiveAlarmsListProps) => {
  return (
    <>
      {alarms.map((alarm) => {
        return (
          <Grow in={true} timeout={500} key={alarm.alarmId}>
            <AlarmCard
              alarm={alarm}
              updateAlarmCard={userUpdateAlarm}
              setFeedback={setFeedback}
            />
          </Grow>
        );
      })}
    </>
  );
};
