import { Fab, makeStyles } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useEffect, useState } from 'react';

export const ScrollToTop = () => {
  const [scrollBtnVisible, setScrollBtnVisible] = useState(false);
  const classes = useStyles();

  const handleScroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setScrollBtnVisible(true);
    } else {
      setScrollBtnVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      handleScroll();
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  if (!scrollBtnVisible) return null;

  return (
    <Fab className={classes.fab} onClick={handleScrollToTop}>
      <ArrowUpwardIcon />
    </Fab>
  );
};

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(1),
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.grey[400],
  },
}));
