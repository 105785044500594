import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import SelectBase from 'components/Input/Select/SelectBase';
import { ResolvedStatus } from 'types/alarmTypes';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

type ResolvedFilterProps = {
  value: ResolvedStatus;
  setValue: Dispatch<SetStateAction<ResolvedStatus>>;
};

export const ResolvedFilter = ({ value, setValue }: ResolvedFilterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = [
    {
      value: ResolvedStatus.UNRESOLVED,
      label: t(`alarms.${ResolvedStatus.UNRESOLVED}`),
    },
    {
      value: ResolvedStatus.RESOLVED,
      label: t(`alarms.${ResolvedStatus.RESOLVED}`),
    },
    { value: ResolvedStatus.ALL, label: t(`alarms.${ResolvedStatus.ALL}`) },
  ];

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setValue(event.target.value as ResolvedStatus);
  };

  return (
    <SelectBase
      items={items}
      value={value}
      onChange={handleChange}
      label={t('alarms.resolve-status')}
      className={classes.resolvedFilter}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  resolvedFilter: {
    maxWidth: '150px',
    marginRight: theme.spacing(4),
  },
}));
