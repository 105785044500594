import {
  FormControlLabel,
  Radio,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterMode } from 'views/AlarmsView/filters/TimeFilters';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { disabledTime } from 'views/AlarmsView/helpers';
import plLocale from 'antd/es/date-picker/locale/pl_PL';
import esLocale from 'antd/es/date-picker/locale/es_ES';
import ptLocale from 'antd/es/date-picker/locale/pt_PT';
import enLocale from 'antd/es/date-picker/locale/en_US';
import i18n from 'translations/i18n';

declare type EventValue<DateType> = DateType | null;
export declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null;

type TimeframeFilterProps = {
  mode: FilterMode;
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  timeframe: { from: Dayjs | null; to: Dayjs | null };
  setTimeframe: Dispatch<
    SetStateAction<{ from: Dayjs | null; to: Dayjs | null }>
  >;
};

export const TimeframeFilter = ({
  mode,
  handleCheckboxChange,
  timeframe,
  setTimeframe,
}: TimeframeFilterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleDateFromChange = (date: Dayjs | null) => {
    setTimeframe({ ...timeframe, from: date });
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setTimeframe({ ...timeframe, to: date });
  };

  const determineLocale = () => {
    const language = i18n.language;
    switch (language) {
      case 'pl':
        return plLocale;
      case 'en':
        return enLocale;
      case 'es':
        return esLocale;
      case 'pt':
        return ptLocale;
    }
  };

  const disabled = !mode.timeframe;

  return (
    <div className={classes.timeframeFilter}>
      <FormControlLabel
        control={
          <Radio
            checked={mode.timeframe}
            name="timeframe"
            onChange={handleCheckboxChange}
          />
        }
        label={t('alarms.timeframe')}
        className={classes.label}
      />
      <Typography variant="body2" className={clsx(classes.fromText)}>
        {t('from')}
      </Typography>
      <DatePicker
        locale={determineLocale()}
        value={timeframe.from}
        onChange={handleDateFromChange}
        format={'YYYY-MM-DD HH:mm'}
        showTime={{ format: 'HH:mm' }}
        showNow={false}
        needConfirm={false}
        className={classes.datePicker}
        maxDate={dayjs()}
        disabled={disabled}
        allowClear={false}
        disabledTime={disabledTime}
      />
      <Typography variant="body2" className={clsx(classes.toText)}>
        {t('to')}
      </Typography>
      <DatePicker
        locale={determineLocale()}
        value={timeframe.to}
        onChange={handleDateToChange}
        format={'YYYY-MM-DD HH:mm'}
        showTime={{ format: 'HH:mm' }}
        needConfirm={false}
        className={classes.datePicker}
        maxDate={dayjs()}
        disabled={disabled}
        disabledTime={disabledTime}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  datePicker: {
    borderColor: 'black',
  },
  timeframeFilter: {
    margin: theme.spacing(0, 2, 0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  toText: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  fromText: {
    marginRight: theme.spacing(1),
  },
  disabledLabel: {
    color: theme.palette.text.disabled,
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
    marginRight: theme.spacing(0.5),
  },
}));
