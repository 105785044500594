import { FormControlLabel, Radio, makeStyles } from '@material-ui/core';
import SelectBase from 'components/Input/Select/SelectBase';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';
import { useTranslation } from 'react-i18next';
import { FilterMode } from 'views/AlarmsView/filters/TimeFilters';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { blockNonNumericalChars } from 'utils/helpers';

type FromLastFilterProps = {
  mode: FilterMode;
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fromLast: { number: number | string; unit: string };
  setFromLast: Dispatch<
    SetStateAction<{ number: number | string; unit: string }>
  >;
};

export const FromLastFilter = ({
  mode,
  handleCheckboxChange,
  fromLast,
  setFromLast,
}: FromLastFilterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const selectItems = [
    { value: 'minutes', label: t('alarms.minutes') },
    { value: 'hours', label: t('alarms.hours') },
    { value: 'days', label: t('alarms.days') },
  ];

  const handleUnitChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setFromLast({ ...fromLast, unit: event.target.value as string });
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (parseInt(value) > 999) {
      return;
    }
    setFromLast({ ...fromLast, number: value ? parseInt(value) : '' });
  };

  const disabled = !mode.lastAlarms;

  return (
    <div className={classes.fromLastContainer}>
      <FormControlLabel
        control={
          <Radio
            checked={mode.lastAlarms}
            onChange={handleCheckboxChange}
            name="lastAlarms"
          />
        }
        label={t('alarms.from-last')}
        className={classes.label}
      />
      <TextFieldBase
        className={classes.timeInput}
        disabled={disabled}
        type="number"
        value={fromLast.number}
        onKeyDown={blockNonNumericalChars}
        onChange={handleNumberChange}
        inputProps={{ min: 1, max: 999, style: { fontWeight: 400 } }}
      />
      <SelectBase
        value={fromLast.unit}
        items={selectItems}
        onChange={handleUnitChange}
        disabled={disabled}
        className={classes.unitSelect}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fromLastContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 3, 0, 3),
  },
  timeInput: {
    maxWidth: '100px',
    minWidth: '40px',
    marginTop: theme.spacing(2),
  },
  unitSelect: {
    maxWidth: '100px',
    minWidth: '90px',
    marginLeft: theme.spacing(2),
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
  },
}));
