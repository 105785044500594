import { AlarmFiltersType } from 'context/providers/AlarmFiltersProvider';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';
import {
  Alarm,
  ApiUpdateAlarmDTO,
  GetAlarmsDTO,
  UserUpdateAlarmDTO,
} from 'types/alarmTypes';

export const apiUpdateAlarm = (
  payload: ApiUpdateAlarmDTO,
  alarmsToUpdate: Alarm[]
) => {
  const alarmToUpdate = alarmsToUpdate.find(
    (alarm) => alarm.alarmId === payload.alarmId
  );

  if (alarmToUpdate) {
    alarmToUpdate.thumbnail = payload.thumbnail || alarmToUpdate.thumbnail;
    alarmToUpdate.image = payload.image || alarmToUpdate.image;
    alarmToUpdate.video = payload.video || alarmToUpdate.video;
    alarmToUpdate.endTimestamp =
      payload.endTimestamp || alarmToUpdate.endTimestamp;

    const idxToUpdate = alarmsToUpdate.findIndex(
      (item) => item.alarmId === alarmToUpdate.alarmId
    );
    alarmsToUpdate.splice(idxToUpdate, 1, alarmToUpdate);
  }
  return alarmsToUpdate;
};

export const updateAlarmActions = (
  updatedId: string,
  payload: UserUpdateAlarmDTO,
  alarmsToUpdate: Alarm[]
) => {
  const alarmToUpdate = alarmsToUpdate.find(
    (alarm) => alarm.alarmId === updatedId
  );

  if (alarmToUpdate) {
    alarmToUpdate.isIgnored = payload.isIgnored;
    alarmToUpdate.isFavourite = payload.isFavourite;
    alarmToUpdate.isResolved = payload.isResolved;

    const idxToUpdate = alarmsToUpdate.findIndex(
      (item) => item.alarmId === alarmToUpdate.alarmId
    );
    alarmsToUpdate.splice(idxToUpdate, 1, alarmToUpdate);
  }
  return alarmsToUpdate;
};

const convertTimeframe = (date: Dayjs | null) => {
  return date?.toISOString() || '';
};

const convertFromLast = (fromLast: {
  number: string | number;
  unit: string;
}) => {
  const date = new Date();
  switch (fromLast.unit) {
    case 'days':
      date.setDate(date.getDate() - Number(fromLast.number));
      break;
    case 'hours':
      date.setHours(date.getHours() - Number(fromLast.number));
      break;
    case 'minutes':
      date.setMinutes(date.getMinutes() - Number(fromLast.number));
      break;
  }
  return date.toISOString();
};

export const convertToApiValues = (filterValues: AlarmFiltersType) => {
  const streamIds = filterValues.streams.map((stream) => stream.id);
  const tagIds = filterValues.zones.map((zone) => zone.id);
  const analyticsTypes = filterValues.analytics.map(
    (analytics) => analytics.name as AnalyticsTypes
  );
  let timeFrameFrom = '';
  let timeFrameTo = '';

  if (filterValues.mode.timeframe) {
    timeFrameFrom = convertTimeframe(filterValues.timeframe.from);
    timeFrameTo =
      convertTimeframe(filterValues.timeframe.to) || new Date().toISOString();
  } else if (filterValues.mode.lastAlarms) {
    timeFrameFrom = convertFromLast(filterValues.fromLast);
    timeFrameTo = new Date().toISOString();
  }

  return {
    streamIds,
    tagIds,
    analyticsTypes,
    favouriteOnly: filterValues.favouriteOnly,
    ignoredStatus: filterValues.ignoredStatus,
    resolvedStatus: filterValues.resolvedStatus,
    liveMode: filterValues.mode.liveMode,
    timeFrameFrom: timeFrameFrom,
    timeFrameTo: timeFrameTo,
  };
};

export const constructParams = (apiFilters: GetAlarmsDTO) => {
  const filters = Object.entries(apiFilters);
  let params = '';

  filters.forEach((filter) => {
    if ((Array.isArray(filter[1]) && isEmpty(filter[1])) || filter[1] === '') {
      return;
    } else {
      params = !params
        ? params.concat(`?${`${filter[0]}=${filter[1]}`}`)
        : params.concat(`&${`${filter[0]}=${filter[1]}`}`);
    }
  });
  return params;
};

export const disabledTime = (selectedDate: Dayjs) => {
  const selectedHour = selectedDate.hour();
  const hours: number[] = [];
  const currentHour = dayjs().hour();
  for (let i = currentHour + 1; i <= 24; i++) {
    hours.push(i);
  }

  const minutes: number[] = [];
  const currentMinute = dayjs().minute();
  if (selectedHour === dayjs().hour()) {
    for (let i = currentMinute; i <= 60; i++) {
      minutes.push(i);
    }
  }

  return {
    disabledHours: () => hours,
    disabledMinutes: () => minutes,
  };
};
