import {
  MultipleSelect,
  MultipleSelectItem,
} from 'components/Input/Select/MultipleSelect';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getStreams } from 'api/streamApi';
import { Stream } from 'types/streamTypes';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type StreamsFilterType = {
  selectedStreams: MultipleSelectItem[];
  setSelectedStreams: Dispatch<SetStateAction<MultipleSelectItem[]>>;
};

export const StreamsFilter = ({
  selectedStreams,
  setSelectedStreams,
}: StreamsFilterType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [inputValues, setInputValues] = useState<MultipleSelectItem[]>([]);

  useEffect(() => {
    getStreams().then((streams) => {
      const formattedValues = streams.map((stream: Stream) => {
        return {
          id: stream.id,
          name: stream.name,
        };
      });
      setInputValues(formattedValues);
    });
  }, []);

  return (
    <MultipleSelect
      label={t('alarms.filter-streams')}
      items={inputValues}
      value={selectedStreams}
      setValue={setSelectedStreams}
      translated={false}
      className={classes.streamsFilter}
      formControlClass={classes.formControlClass}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  streamsFilter: {
    marginRight: theme.spacing(4),
  },
  formControlClass: {
    maxWidth: '350px',
  },
}));
