import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  makeStyles,
  Typography,
  SelectProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export type MultipleSelectItem = { id: string; name: string };

enum AnchorOriginHorizontal {
  LEFT = 'left',
  RIGHT = 'right',
}

enum AnchorOriginVertical {
  BOTTOM = 'bottom',
  TOP = 'top',
  CENTER = 'center',
}

const MenuProps = {
  anchorOrigin: {
    vertical: AnchorOriginVertical.BOTTOM,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  transformOrigin: {
    vertical: AnchorOriginVertical.TOP,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  getContentAnchorEl: null,
};

type MultipleSelectProps = SelectProps & {
  items: MultipleSelectItem[];
  value: MultipleSelectItem[];
  setValue: Dispatch<SetStateAction<MultipleSelectItem[]>>;
  translated: boolean;
  formControlClass?: string;
  selectClass?: string;
  translationKey?: string;
};

export const MultipleSelect = ({
  items,
  label,
  value,
  setValue,
  formControlClass,
  selectClass,
  displayEmpty,
  disabled,
  translated,
  translationKey,
  ...props
}: MultipleSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (wasChecked: boolean, item: MultipleSelectItem) => {
    let newItems = [...value];

    if (wasChecked) {
      newItems = newItems.filter((stream) => stream.id !== item.id);
    } else {
      newItems.push(item);
    }
    setValue(newItems);
  };

  return (
    <FormControl
      className={clsx(formControlClass, classes.formControl, {
        [classes.withoutLabel]: !label,
      })}
    >
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Select
        multiple
        input={<Input />}
        value={value}
        displayEmpty
        renderValue={(selected) => {
          const displayValues = (selected as MultipleSelectItem[]).map(
            (item) => {
              return translated
                ? t(`${translationKey}.${item.name}`)
                : item.name;
            }
          );
          if (isEmpty(selected) && displayEmpty) {
            return (
              <Typography className={classes.input}>
                {t('multi-select.choose')}
              </Typography>
            );
          }
          return (
            <Typography noWrap className={classes.input}>
              {(displayValues as string[]).map((item) => item).join(', ')}
            </Typography>
          );
        }}
        className={clsx(selectClass, classes.customSelect)}
        MenuProps={{ ...MenuProps, classes: { paper: classes.selectMenu } }}
        {...props}
      >
        {items.map((item) => {
          const checked = value
            .map((selected) => selected.id)
            .includes(item.id);

          return (
            <MenuItem
              id={item.id}
              key={item.id}
              value={item.name}
              className={classes.menuItem}
              disabled={disabled}
              onClick={() => handleChange(checked, item)}
            >
              <Checkbox checked={checked} />
              <ListItemText
                primary={
                  translated ? t(`${translationKey}.${item.name}`) : item.name
                }
                className={clsx(classes.text)}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  withoutLabel: {
    marginTop: theme.spacing(0.5),
  },
  text: {
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  disabled: {
    '& .MuiListItemText-primary': {
      color: theme.palette.grey[300],
    },
  },
  menuItem: {
    height: '36px',
  },
  selectMenu: {
    maxHeight: 360,
    border: '1px solid black',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
  },
  input: {
    fontWeight: 400,
    height: '20px',
  },
  label: {
    fontWeight: 400,
  },
  customSelect: {
    '& .MuiSelect-root': {
      minHeight: '24px',
    },
  },
}));
