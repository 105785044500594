import { Chip, Divider, Typography, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Alarm } from 'types/alarmTypes';

type AlarmCardDetailsProps = {
  alarm: Alarm;
};

export const AlarmCardDetails = ({ alarm }: AlarmCardDetailsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    eventType,
    startTimestamp,
    endTimestamp,
    analyticsType,
    tags,
    streamName,
  } = alarm;

  return (
    <div className={classes.details}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          {t(`alarms.types.${eventType}`)}
        </Typography>
        {!alarm.endTimestamp && (
          <Chip label={t('alarms.now')} className={classes.chip} />
        )}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.dataRow}>
        <Typography className={classes.label}>
          {t('alarms.start-date')}
        </Typography>
        <Typography className={classes.value}>
          {new Date(startTimestamp).toLocaleString()}
        </Typography>
      </div>
      {endTimestamp && (
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            {t('alarms.end-date')}
          </Typography>
          <Typography className={classes.value}>
            {new Date(endTimestamp).toLocaleString()}
          </Typography>
        </div>
      )}
      <div className={classes.dataRow}>
        <Typography className={classes.label}>
          {t('alarms.analytics')}
        </Typography>
        <Typography className={classes.value}>
          {t(`analytics.${analyticsType}`)}
        </Typography>
      </div>
      {!isEmpty(tags) && (
        <div className={classes.dataRow}>
          <Typography className={classes.label}>{t('alarms.zone')}</Typography>
          <Typography className={classes.value}>{tags[0]?.tagName}</Typography>
        </div>
      )}
      <div className={classes.dataRow}>
        <Typography className={classes.label}>
          {t('alarms.stream-name')}
        </Typography>
        <Typography className={classes.value}>{streamName}</Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataRow: {
    display: 'flex',
  },
  label: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  value: {
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  details: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontWeight: 400,
    marginRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
  },
  chip: {
    backgroundColor: theme.palette.secondary.background2,
    fontSize: '12px',
    height: '28px',
    textTransform: 'uppercase',
  },
}));
