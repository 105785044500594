import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { NavWrapper } from 'wrappers/NavWrapper';
import StreamsView from 'views/StreamsView/StreamsView';
import UsersView from 'views/UsersView/UsersView';
import StreamDetails from 'views/StreamDetails/StreamDetails';
import SettingsView from 'views/SettingsView/SettingsView';
import AlarmsView from 'views/AlarmsView/AlarmsView';
import { LicenseProvider } from 'context/providers/LicenseProvider';
import { useAuth } from 'context/providers/AuthProvider';
import { ChangePassDialog } from 'views/PassChange/ChangePassDialog';
import { AnalyticsProvider } from 'context/providers/AnalyticsProvider';
import { VideoWallProvider } from 'context/providers/VideoWallProvider';
import { StreamDataProvider } from 'context/providers/StreamDataProvider';
import { StreamsAnalyticsProvider } from 'context/providers/StreamsAnalyticsProvider';
import { VideoWall } from 'views/VideoWall/VideoWall';
import { AnalyticsSettingsProvider } from 'context/providers/AnalyticsSettingsProvider';
import { CameraZonesProvider } from 'context/providers/CameraZonesProvider';
import { AlarmFiltersProvider } from 'context/providers/AlarmFiltersProvider';

const AuthenticatedApp = () => {
  const { loggedInUser, isAdmin } = useAuth();

  return (
    <>
      <LicenseProvider>
        <AnalyticsProvider>
          <CameraZonesProvider>
            <VideoWallProvider>
              <StreamsAnalyticsProvider>
                <Routes>
                  <Route element={<NavWrapper />}>
                    <Route path="/streams" element={<Outlet />}>
                      <Route index element={<StreamsView />} />
                      <Route
                        path=":streamId"
                        element={
                          <StreamDataProvider>
                            <AnalyticsSettingsProvider>
                              <StreamDetails />
                            </AnalyticsSettingsProvider>
                          </StreamDataProvider>
                        }
                      />
                      <Route path="video-wall" element={<VideoWall />} />
                    </Route>
                    <Route
                      path="alarms"
                      element={
                        <AlarmFiltersProvider>
                          <AlarmsView />
                        </AlarmFiltersProvider>
                      }
                    />
                    {isAdmin && (
                      <>
                        <Route path="/users" element={<UsersView />} />
                        <Route path="/settings" element={<SettingsView />} />
                      </>
                    )}
                    <Route path="*" element={<Navigate to="/streams" />} />
                  </Route>
                </Routes>
              </StreamsAnalyticsProvider>
            </VideoWallProvider>
          </CameraZonesProvider>
          <ChangePassDialog
            open={Boolean(loggedInUser && loggedInUser.mustChangePassword)}
          />
        </AnalyticsProvider>
      </LicenseProvider>
    </>
  );
};

export default AuthenticatedApp;
