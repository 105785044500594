import { makeStyles } from '@material-ui/core';
import {
  MultipleSelect,
  MultipleSelectItem,
} from 'components/Input/Select/MultipleSelect';
import { useCameraZones } from 'context/providers/CameraZonesProvider';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CameraZoneFilterType = {
  selectedZones: MultipleSelectItem[];
  setSelectedZones: Dispatch<SetStateAction<MultipleSelectItem[]>>;
};

export const CameraZoneFilter = ({
  selectedZones,
  setSelectedZones,
}: CameraZoneFilterType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cameraZones } = useCameraZones();
  const [inputValues, setInputValues] = useState<MultipleSelectItem[]>([]);

  useEffect(() => {
    const formattedValues = cameraZones.map((zone) => {
      return {
        id: zone.id,
        name: zone.name,
      };
    });
    setInputValues(formattedValues);
  }, [cameraZones]);

  return (
    <MultipleSelect
      label={t('alarms.filter-zones')}
      items={inputValues}
      value={selectedZones}
      setValue={setSelectedZones}
      translated={false}
      className={classes.zoneFilter}
      formControlClass={classes.formControlClass}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  zoneFilter: {
    marginRight: theme.spacing(4),
  },
  formControlClass: {
    maxWidth: '350px',
  },
}));
