import {
  CardContent,
  Collapse,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { apiAddress } from 'api/config/interceptors';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alarm, AlarmMediaStatus } from 'types/alarmTypes';

type AlarmCardMediaProps = {
  expanded: boolean;
  alarm: Alarm;
};

type MediaComponentProps = {
  alarmId: string;
};

const ImageComponent = ({ alarmId }: MediaComponentProps) => {
  const classes = useStyles();

  return (
    <img
      src={`${apiAddress}/api/${alarmId}/image`}
      alt="alarm-img"
      draggable="false"
      className={classes.img}
    />
  );
};

const VideoComponent = ({ alarmId }: MediaComponentProps) => {
  const classes = useStyles();

  return (
    <video controls className={classes.video}>
      <source src={`${apiAddress}/api/${alarmId}/video`} type="video/mp4" />
    </video>
  );
};

export const AlarmCardMedia = ({ expanded, alarm }: AlarmCardMediaProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const determineMediaContent = (type: 'image' | 'video') => {
    const status = type === 'image' ? alarm.image.status : alarm.video.status;
    if (status === AlarmMediaStatus.AVAILABLE) {
      return (
        <>
          {type === 'image' ? (
            <ImageComponent alarmId={alarm.alarmId} />
          ) : (
            <VideoComponent alarmId={alarm.alarmId} />
          )}
        </>
      );
    } else if (status === AlarmMediaStatus.NOT_YET_AVAILABLE) {
      return <Skeleton variant="rect" className={classes.skeleton} />;
    } else {
      return (
        <div className={classes.placeholder}>
          <Typography variant="subtitle1" className={classes.placeholderText}>
            {type === 'image'
              ? t('alarms.image-not-available')
              : t('alarms.video-not-available')}
          </Typography>
        </div>
      );
    }
  };

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <Divider className={classes.divider} />
      <CardContent className={classes.contentWrapper}>
        <div className={classes.alarmMedia}>
          <div className={classes.imgWrapper}>
            {determineMediaContent('image')}
          </div>
          <div className={classes.videoWrapper}>
            {determineMediaContent('video')}
          </div>
        </div>
        <div className={classes.linkContainer}>
          <Link
            to={`/streams/${alarm.streamId}`}
            target="_blank"
            className={classes.link}
          >{`${t('alarms.live-preview')} →`}</Link>
        </div>
      </CardContent>
    </Collapse>
  );
};

const useStyles = makeStyles((theme) => ({
  alarmMedia: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  contentWrapper: {
    padding: theme.spacing(3),
  },
  img: {
    backgroundColor: theme.palette.grey[600],
    aspectRatio: '16/9',
    verticalAlign: 'bottom',
    width: '100%',
    maxWidth: '100%',
    borderRadius: '4px',
  },
  video: {
    backgroundColor: theme.palette.grey[600],
    width: '100%',
    maxWidth: '100%',
    aspectRatio: '16/9',
    verticalAlign: 'bottom',
    borderRadius: '4px',
  },
  videoWrapper: {
    flex: '1 1 0',
    aspectRatio: '16/9',
  },
  imgWrapper: {
    flex: '1 1 0',
    aspectRatio: '16/9',
    marginRight: theme.spacing(3),
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(0, 3, 0, 3),
  },
  skeleton: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[600],
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderText: {
    fontWeight: 400,
  },
  link: {
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
