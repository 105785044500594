import { FormControlLabel, Radio, makeStyles } from '@material-ui/core';
import { Dayjs } from 'dayjs';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FromLastFilter } from 'views/AlarmsView/filters/FromLastFilter';
import { TimeframeFilter } from 'views/AlarmsView/filters/TimeframeFilter';

export type FilterMode = {
  liveMode: boolean;
  lastAlarms: boolean;
  timeframe: boolean;
};

type TimeFiltersProps = {
  mode: FilterMode;
  setMode: Dispatch<SetStateAction<FilterMode>>;
  timeframe: { from: Dayjs | null; to: Dayjs | null };
  setTimeframe: Dispatch<
    SetStateAction<{ from: Dayjs | null; to: Dayjs | null }>
  >;
  fromLast: { number: number | string; unit: string };
  setFromLast: Dispatch<
    SetStateAction<{ number: number | string; unit: string }>
  >;
};

export const TimeFilters = ({
  mode,
  setMode,
  timeframe,
  setTimeframe,
  fromLast,
  setFromLast,
}: TimeFiltersProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      return;
    }
    const modeCopy = { ...mode };
    if (event.target.checked) {
      for (const modeItem in modeCopy) {
        modeCopy[modeItem as keyof FilterMode] = false;
      }
    }
    setMode({ ...modeCopy, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.timeFilters}>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={mode.liveMode}
              onChange={handleCheckboxChange}
              name="liveMode"
            />
          }
          label={t('alarms.live-mode')}
          className={classes.label}
        />
      </div>
      <FromLastFilter
        mode={mode}
        handleCheckboxChange={handleCheckboxChange}
        fromLast={fromLast}
        setFromLast={setFromLast}
      />
      <TimeframeFilter
        mode={mode}
        handleCheckboxChange={handleCheckboxChange}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  timeFilters: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      whiteSpace: 'nowrap',
    },
  },
}));
